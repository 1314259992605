import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import Banner from "../../components/Content/Banner/Index";
import LeadIn from "../../components/Content/LeadIn/Index";
import FeatureApplication from "../../components/Content/FeatureApplication/Index";
import Col2 from "../../components/Content/TextMedia/Col-2";
import Col1 from "../../components/Content/TextMedia/Col-1";
import TextIcon from "../../components/Content/TextIcon/Index";
import Video from "../../components/Content/Video/Index";

// data
import data from "../static-pages/landing/bpress.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title=">B< Press"
        description=">B< Press is a versatile press fitting system that provides a secure, permanent and leak-proof joint suitable for a variety of applications."
      />
      <Banner
        type="product"
        title="Fitting System"
        summary={`&frac12;″ - 2″`}
        divider={true}
        brandMediaUrl="/logos/logo-bpress.svg"
        brandMediaWidth="400px"
        featureMediaUrl="/banner/banner-bpress.jpg"
        featureMediaTitle=">B< Press Fitting"
        removePadding={true}
      />
      <LeadIn
        section="default"
        subHeading="Why choose"
        brandMediaUrl="/logos/logo-bpress.svg"
        brandMediaWidth="280px"
        brandMediaTitle=">B< Press"
        summary=">B< Press is a versatile press fitting system that provides a secure, permanent and leak-proof joint suitable for a variety of applications. <br/><br/><a href='/products/?brand=>B<+Press' class='uk-button uk-button-primary'>View Range</a>"
        buttonValue="Download Technical Guide"
        buttonUrl="/downloads/B-Press-Technical-Brochure.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
        paddingSize="large"
      >
        <TextIcon data={data.facts} />
      </LeadIn>
      <FeatureApplication
        sectionId="1"
        section="muted"
        heading="Applications"
        content=">B< Press is suitable for use across a wide range of applications depending on medium, temperature and pressure. To find out more, download our application guide."
        divider={true}
        buttonValue="Download Technical Guide"
        buttonUrl="/downloads/B-Press-Technical-Brochure.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        data={data.tabs}
      />
      <LeadIn
        section="muted"
        brandHeading="Features"
        paddingSize="small"
        divider={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>O-Ring</h2>
          <p>Black >B< Press EPDM O-ring's are peroxide cured rubber seals with high elasticity, excellent cold and heat performance.</p>
        "
        backgroundMediaUrl="/products/landing/black-oring.jpg"
        backgroundMediaTitle="Black >B< Press EPDM O-ring indicator"
        mediaPositionClass="left"
        removePadding={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>Leak Indicator</h2>
          <p>The unique pressing indicator is a specially designed O-ring. It has a reduced section in two positions that allows water or air past the sealing element of any unpressed connection, thereby providing a visible leakage point during the commissioning of the system.</p>
          <p>Unpressed fittings are identified by pressurising the system with a pressure range of 14.5psi  - 72.5psi for water (prior to the final pressure testing) and .32psi - 43.5psi for gas. When the fitting is pressed, the O-ring material compresses, filling the gaps and creating a leak-free joint.</p>
        "
        backgroundMediaUrl="/products/landing/leak-indicator.png"
        backgroundMediaTitle="Leak Indicator Diagram"
        mediaPositionClass="right"
        removePadding={true}
      />
      <Col1
        section="muted"
        heading="3-Point Press"
        summary="The >B< profile has the advantage of two mechanical presses either side of the bead and one hydraulic press crimping the O-ring. This provides a permanent and secure joint. The lead-in edge design aids installation and helps protect the O-ring from damage or displacement."
        backgroundMediaUrl="/products/landing/three-point-press-diagram.jpg"
        backgroundMediaTitle="3-Point Press Diagram"
        buttonBottom={true}
        buttonValue="View Range"
        buttonUrl="/products/?brand=>B<+Press"
        removePadding={true}
      />
      <LeadIn
        brandHeading="How to install"
        buttonValue="Download Installation Guide"
        buttonUrl="/downloads/B-Press-Installation-Instructions.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
      >
        <Video
          margin={true}
          videoMediaType="youtube"
          videoMediaId="PmELo1E25vY"
          backgroundMediaUrl="/products/landing/video-installation.jpg"
          backgroundMediaTitle="Installation"
          backgroundMediaWidth="800px"
        />
      </LeadIn>
      <LeadIn
        section="muted"
        subHeading="50 year"
        brandHeading="Warranty"
        divider={true}
        summary="A 50 year warranty covers against faults caused by defective manufacture of the fittings. It does not cover faults arising from incorrect installation."
        content="All >B< Press fittings must be installed by a licensed plumber."
        buttonValue="Download Warranty"
        buttonUrl="/downloads/B-Press-Limited-Warranty.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
      />
    </Layout>
  );
};

export default IndexPage;
